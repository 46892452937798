<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
import { debounce } from 'vue-debounce';
export default {
	methods: {
		on_resize () {
			this.$store.commit('SET_SCREEN_SIZE', {
				height: window.innerHeight,
				width: window.innerWidth,
			});
		},
	},
	async mounted () {
		this.$store.commit('SET_SCREEN_SIZE', {
			height: window.innerHeight,
			width: window.innerWidth,
		});
		window.addEventListener('resize', debounce(this.on_resize, 10));
		if (this.$cookies.get('language') !== null) {
			this.$i18n.locale = this.$cookies.get('language');
		}
	},
	beforeDestroy () {
		window.removeEventListener('resize', this.on_resize);
	},
};
</script>
<style lang="scss">
#app {
  font-family: Roboto, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background-color: #F9F8FB;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
div{
  scrollbar-width: thin !important;
}
.v-data-table-header th {
  white-space: nowrap;
}

</style>
