/* eslint-disable no-unused-vars */
import Repository from '../../repositories/RepositoryFactory';

const libphonenumber = require('libphonenumber-js');
const MedicalInstitutionsRepository = Repository.get('medicalInstitutions');

export default {
	namespaced: true,
	state: {
		medicalInstitutions: null,
		detailedMedicalInstitution: null,
		selectedMedicalInstitution: null,
		relatedOrganizationPatients: null,
		relatedOrganizationMedicalStaff: null,
	},
	mutations: {
		GET_MEDICAL_INSTITUTIONS (state, payload) {
			payload.data.forEach(c => {
				if (c.contactPhone.includes('+')) {
					c.contactPhone = libphonenumber.parsePhoneNumberFromString(c.contactPhone).formatInternational();
				}
			});
			state.medicalInstitutions = payload.data;
		},
		GET_MEDICAL_INSTITUTIONS_BY_ID (state, payload) {
			// state.detailedMedicalInstitution = payload.medicalInstitutionData;
			state.detailedMedicalInstitution = payload;
		},
		SELECTED_MEDICAL_INSTITUTION (state, payload) {
			state.selectedMedicalInstitution = payload;
		},
		GET_RELATED_ORGANIZATION_PATIENTS (state, payload) {
			state.relatedOrganizationPatients = payload.data.usersRelatedToOrganization;
		},
		GET_RELATED_ORGANIZATION_MEDICAL_STAFF (state, payload) {
			state.relatedOrganizationMedicalStaff = payload.data.usersRelatedToOrganization;
		},
	},
	actions: {
		async getMedicalInstitutions ({ commit }) {
			commit('GET_MEDICAL_INSTITUTIONS', await MedicalInstitutionsRepository.getMedicalInstitutions());
		},
		async getMedicalInstitutionById ({ commit }, id) {
			commit('GET_MEDICAL_INSTITUTIONS_BY_ID', await MedicalInstitutionsRepository.getMedicalInstitutionById(id));
		},
		async createMedicalInstitution ({ commit }, body) {
			const res = await MedicalInstitutionsRepository.createMedicalInstitution(body);
			return res;
		},
		async editMedicalInstitution ({ commit }, body) {
			const res = await MedicalInstitutionsRepository.editMedicalInstitution(body);
			return res;
		},
		async deleteMedicalInstitution ({ commit }, id) {
			const res = await MedicalInstitutionsRepository.deleteMedicalInstitution(id);
			return res;
		},
		async getRelatedOrganizationPatients ({ commit }, organizationId) {
			commit('GET_RELATED_ORGANIZATION_PATIENTS', await MedicalInstitutionsRepository.getRelatedOrganizationPatients(organizationId));
		},
		async getRelatedOrganizationMedicalStaff ({ commit }, organizationId) {
			commit('GET_RELATED_ORGANIZATION_MEDICAL_STAFF', await MedicalInstitutionsRepository.getRelatedOrganizationMedicalStaff(organizationId));
		},
	},
	getters: {
		getMedicalInstitutions (state) {
			let medicalInstititionsList = state.medicalInstitutions;
			medicalInstititionsList = medicalInstititionsList || [];
			// medicalInstititionsList.unshift({ id: 0, institutionName: 'Select organization', institutionNameESP: 'Select organization' }); why the fuck we add this !!!
			return medicalInstititionsList;
		},
		getMedicalInstitutionsSorted (state) {
			var medicalInstititionsList = state.medicalInstitutions;

			// sort by institutionName
			if (medicalInstititionsList != null) {
				medicalInstititionsList.sort(function (a, b) {
					if (a.institutionName < b.institutionName) {
						return -1;
					}
					if (a.institutionName > b.institutionName) {
						return 1;
					}
					return 0;
				});
			}

			// medicalInstititionsList = medicalInstititionsList || [];
			if (medicalInstititionsList != null) {
				medicalInstititionsList.unshift({ id: 0, institutionName: 'All', institutionNameESP: 'Todo' });
			}

			return medicalInstititionsList;
		},
		getSelectedMedicalInstitution (state) {
			return state.selectedMedicalInstitution;
		},
		getDetailedMedicalInstitution (state) {
			return state.detailedMedicalInstitution;
		},
		getRelatedOrganizationMedicalStaff (state) {
			return state.relatedOrganizationMedicalStaff;
		},
	},
};
