import Repository from '../../repositories/RepositoryFactory';
const VerifiersRepository = Repository.get('verifiers');

export default {
	namespaced: true,
	state: {
		verifiers: null,
		icd10Codes: [],
		timeZones: [],
		allverifires: null,
		specializations: [],
		specializationsForForm: [],
		typesOfDisorder: [],
		typesOfDisorderForForm: [],
		typesOfSurveyForForm: [],
		typeOfSurvey: [],
		healthInsurance: [],
		selectedVerifier: null,
		surveyRecurrence: null,
	},
	mutations: {
		GET_VERIFIERS (state, payload) {
			state.verifiers = payload;
		},
		GET_SURVEY_RECURRENCE (state, payload) {
			state.surveyRecurrence = payload;
		},
		GET_VERIFIERS_MENTAL_HEALTH (state, payload) {
			state.typesOfDisorder = payload;
			state.typesOfDisorderForForm = payload;
		},
		GET_VERIFIERS_SURVEY_TYPE (state, payload) {
			state.typeOfSurvey = payload;
			state.typesOfSurveyForForm = payload;
		},
		GET_TIME_ZONES (state, payload) {
			state.timeZones = payload;
		},
		GET_ALL_VERIFIRES (state, payload) {
			state.allverifires = payload;
			state.specializations = state.allverifires.filter(v => v.rvDomain === 'SPECIALIZATION_TYPE');
		},
		GET_VERIFIERS_SPECIALIZATION (state, payload) {
			state.specializations = payload;
			state.specializationsForForm = payload;
		},
		GET_VERIFIERS_ICD_10_CODE (state, payload) {
			state.icd10Codes = payload;
		},
		GET_HEALTH_INSURANCE_PROGRAM (state, payload) {
			state.healthInsurance = payload;
		},
		GET_VERIFIER_BY_ID (state, payload) {
			state.selectedVerifier = payload;
		},
		DELETE_VERIFIER_BY_ID (state, payload) {
			state.selectedVerifier = payload;
		},
		SELECTED_VERIFIER (state, payload) {
			state.selectedVerifier = payload;
		},
	},
	actions: {
		async getVerifiersForDomain ({ commit }, header) {
			commit('GET_VERIFIERS', await VerifiersRepository.getVerifiersForDomain(header));
		},
		async getVerifiersForDomainSpecialization ({ commit }) {
			commit('GET_VERIFIERS_SPECIALIZATION', await VerifiersRepository.getVerifiersForDomainSpecialization());
		},
		async getICD10CodeForDomainSpecialization ({ commit }) {
			commit('GET_VERIFIERS_ICD_10_CODE', await VerifiersRepository.getICD10CodeForDomainSpecialization());
		},
		async getVerifiersForDomainMentalHealth ({ commit }) {
			commit('GET_VERIFIERS_MENTAL_HEALTH', await VerifiersRepository.getVerifiersForDomainMentalHealth());
		},
		async getVerifiersForDomainSurveyType ({ commit }) {
			commit('GET_VERIFIERS_SURVEY_TYPE', await VerifiersRepository.getVerifiersForDomainSurveyType());
		},
		async getVerifiersForDomainHealthInsurance ({ commit }) {
			commit('GET_HEALTH_INSURANCE_PROGRAM', await VerifiersRepository.getHealthInsurance());
		},
		async getVerifiersForMultipleDomains ({ commit }, header) {
			commit('GET_VERIFIERS', await VerifiersRepository.getVerifiersForMultipleDomains(header));
		},
		async getSurveyRecurrencePeriod ({ commit }, header) {
			commit('GET_SURVEY_RECURRENCE', await VerifiersRepository.getSurveyRecurrencePeriod(header));
		},

		async getVerifierById ({ commit }, id) {
			commit('GET_VERIFIER_BY_ID', await VerifiersRepository.getVerifierById(id));
		},
		async createVerifire ({ commit }, body) {
			return await VerifiersRepository.createVerifire(body);
		},
		async editVerifire ({ commit }, body) {
			return await VerifiersRepository.editVerifire(body);
		},
		async getVerifiers ({ commit }, header) {
			commit('GET_ALL_VERIFIRES', await VerifiersRepository.getVerifiers(header));
		},
		async getAllVerifiers ({ commit }, header) {
			commit('GET_ALL_VERIFIRES', await VerifiersRepository.getAllVerifiers(header));
		},
		async deleteVerifierById ({ commit }, id) {
			// commit('DELETE_VERIFIER_BY_ID', await VerifiersRepository.deleteVerifierById(id));
			return await VerifiersRepository.deleteVerifierById(id);
		},
		async getTimeZones ({ commit }) {
			commit('GET_TIME_ZONES', await VerifiersRepository.getTimeZones());
		},
	},
	getters: {
		getHealthInsuranceProgram (state) {
			return state?.healthInsurance;
		},
		getSpecializations (state) {
			let specializationsFilter = state.specializations.slice(); // create a shallow copy of the array
			specializationsFilter = specializationsFilter || [];
			specializationsFilter.push({ longDescription: 'All', longDescriptionEsp: 'Toda', lowValue: -1 });
			specializationsFilter.reverse();
			return specializationsFilter;
			// const specializationsFilter = state.specializations;
			// state.specializations.push('MARIO');
		},
		getSpecializationsForForm (state) {
			return state.specializationsForForm;
		},
		getMentalHealthCategoryForForm (state) {
			return state.typesOfDisorderForForm;
		},
		getSurveyTypesForForm (state) {
			const surveyTypes = state.typesOfSurveyForForm.filter(item => item.lowValue !== 4);
			return surveyTypes;
		},
		getTimeZone (state) {
			return state.timeZones;
		},
		getICD10Codes (state) {
			const codes = state.icd10Codes;
			codes.sort((a, b) => parseFloat(a.lowValue) - parseFloat(b.lowValue));
			return codes;
		},
		getSelectedVerifier (state) {
			return state.selectedVerifier;
		},
		getAllVerifiers (state) {
			return state.allverifires;
		},
	},
};
