/* eslint-disable no-unused-vars */
import Repository from '../../repositories/RepositoryFactory';

const MentalHealthRepository = Repository.get('mentalhealth');

export default {
	namespaced: true,
	state: {
		surveys: [],
		survey: {},
		surveyPatentDetails: {},
		surveyAssignedById: {},
		recurrence: [],
		surveyRecurrenceId: undefined,
		surveyReportForPatient: null,
		relatedSurveys: [],
	},
	mutations: {
		GET_SURVEYS (state, payload) {
			state.surveys = payload.data;
		},
		GET_SURVEY_BY_ID (state, payload) {
			state.survey = payload.data;
		},
		GET_SURVEY_FOR_PATIENT (state, payload) {
			state.surveyPatentDetails = payload.data;
		},
		GET_SURVEY_ASSIGNED_BY_ID (state, payload) {
			state.surveyAssignedById = payload.data;
		},
		GET_SURVEY_RECURRENCE (state, payload) {
			state.recurrence = payload.data;
		},
		UPDATE_FILTERS_RECURRENCE: (state, payload) => {
			state.surveyRecurrenceId = payload;
		},
		GET_SURVEY_REPORTS_PATIENT: (state, payload) => {
			state.surveyReportForPatient = payload.data;
		},
		CLEAR_CURRENT_SURVEY_TEMPLATE (state, payload) {
			state.survey = {};
		},
		GET_RELATED_SURVEYS (state, payload) {
			state.relatedSurveys = payload;
		},
	},
	actions: {
		async updateFiltersRecurrence ({ commit }, payload) {
			commit('UPDATE_FILTERS_RECURRENCE', payload);
		},
		async stofferAnswerReorder ({ commit }, obj) {
			await MentalHealthRepository.stofferAnswerReorder(obj);
		},
		async assigneMentalHealth ({ commit }, obj) {
			await MentalHealthRepository.assigneMentalHealth(obj);
		},
		async stquestionReorder ({ commit }, obj) {
			await MentalHealthRepository.stquestionReorder(obj);
		},
		async assignSurveyTemplatetoPatient ({ commit }, obj) {
			return await MentalHealthRepository.assignSurveyTemplatetoPatient(obj);
		},
		async assigneMentalHealthEdit ({ commit }, obj) {
			await MentalHealthRepository.assigneMentalHealthEdit(obj);
		},
		async getMentalHealthSurveyTemplates ({ commit }, hospitalId) {
			commit('GET_SURVEYS', await MentalHealthRepository.getMentalHealthSurveyTemplates(hospitalId));
		},
		async getSurveysById ({ commit }, id) {
			commit('GET_SURVEY_BY_ID', await MentalHealthRepository.SurveysById(id));
		},
		async deleteSurveyById ({ commit }, id) {
			return await MentalHealthRepository.deleteSurveyById(id);
		},
		async deleteSurveysById ({ commit }, id) {
			return await MentalHealthRepository.deleteSurveysById(id);
		},
		async createSurvey ({ commit }, obj) {
			return await MentalHealthRepository.createSurvey(obj);
		},
		async createQuestion ({ commit }, obj) {
			return await MentalHealthRepository.createQuestion(obj);
		},
		async updateAnswer ({ commit }, obj) {
			return await MentalHealthRepository.updateAnswer(obj);
		},
		async updateSurveyTemplate ({ commit }, obj) {
			return await MentalHealthRepository.updateSurveyTemplate(obj);
		},
		async updateSurvey ({ commit }, obj) {
			return await MentalHealthRepository.updateSurvey(obj);
		},
		async deleteAnswer ({ commit }, obj) {
			return await MentalHealthRepository.deleteAnswer(obj);
		},
		async createAnswer ({ commit }, obj) {
			return await MentalHealthRepository.createAnswer(obj);
		},
		async updateQuestion ({ commit }, obj) {
			return await MentalHealthRepository.updateQuestion(obj);
		},
		async deleteQuestion ({ commit }, id) {
			return await MentalHealthRepository.deleteQuestion(id);
		},
		async assignSurveyTemplateToClientMonitoringPlan ({ commit }, obj) {
			return await MentalHealthRepository.assignSurveyTemplateToClientMonitoringPlan(obj);
		},
		async DeAssignSurveyFromClientMonitoringPlan ({ commit }, obj) {
			return await MentalHealthRepository.DeAssignSurveyFromClientMonitoringPlan(obj);
		},
		async getListOfTemplates ({ commit }) {
			commit('GET_RELATED_SURVEYS', await MentalHealthRepository.getListOfTemplates());
		},
		async getSurveyForPatient ({ commit }, obj) {
			commit('GET_SURVEY_FOR_PATIENT', await MentalHealthRepository.getSurveyForPatient(obj));
		},
		async getSurveyReportForPatient ({ commit }, obj) {
			commit('GET_SURVEY_REPORTS_PATIENT', await MentalHealthRepository.getSurveyReportForPatient(obj));
		},
		async getSurveyRecurrence ({ commit }, obj) {
			commit('GET_SURVEY_RECURRENCE', await MentalHealthRepository.getSurveyRecurrence(obj));
		},
		async SurveysAssignedById ({ commit }, obj) {
			commit('GET_SURVEY_ASSIGNED_BY_ID', await MentalHealthRepository.SurveysAssignedById(obj));
		},
	},
	getters: {
		getSurveys (state) {
			return state?.surveys;
		},
	},
};
