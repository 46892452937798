import Repository from '../../repositories/RepositoryFactory';

const MedicalDeviceRepository = Repository.get('medicaldevice');

export default {
	namespaced: true,
	state: {
		bloodPresure: [],
		medicalDeviceTemplates: null,
		deviceTemplates: null,
		patientActiveTreatmentPlan: null,
		medicalDevicesList: null,
		medicalDevices: [],
	},
	mutations: {
		LOAD_BLOOD_PRESURE_DATA (state, payload) {
			state.bloodPresure = payload;
		},
		GET_MEDICAL_DEVICE_TEMPLATES (state, payload) {
			state.medicalDeviceTemplates = payload;
		},
		DEVICE_TEMPLATES (state, payload) {
			state.deviceTemplates = payload;
		},
		PATIENT_DEVICES (state, payload) {
			state.patientActiveTreatmentPlan = payload.data.patientActiveTreatmentPlan;
			state.medicalDevicesList = payload.data.medicalDevicesList;
		},
		GET_DEVICES (state, payload) {
			state.medicalDevices = payload;
		},
	},
	actions: {
		// eslint-disable-next-line no-unused-vars
		async createMedicalDevice ({ commit }, body) {
			const res = await MedicalDeviceRepository.createMedicalDevice(body);
			return res;
		},
		async getPossibleDevices ({ commit }) {
			// commit('GET_DEVICES', await MedicalDeviceRepository.getPossibleDevices());
			const res = await MedicalDeviceRepository.getPossibleDevices();
			commit('GET_DEVICES', res);
			return res;
		},
		// async getPossibleDevices2 ({ commit }) {
		// 	commit('GET_DEVICES', await MedicalDeviceRepository.getPossibleDevices2());
		// 	// return res;
		// },
		// eslint-disable-next-line no-unused-vars
		async editMedicalDevice ({ commit }, body) {
			const res = await MedicalDeviceRepository.editMedicalDevice(body);
			return res;
		},
		// eslint-disable-next-line no-unused-vars
		async deleteMedicalDevice ({ commit }, body) {
			const res = await MedicalDeviceRepository.deleteMedicalDevice(body);
			return res;
		},
		// eslint-disable-next-line no-unused-vars
		async getMyMedicalDevices ({ commit }, id) {
			const res = await MedicalDeviceRepository.getMyMedicalDevices(id);
			return res;
		},
		// eslint-disable-next-line no-unused-vars
		async getDeviceById ({ commit }, id) {
			const res = await MedicalDeviceRepository.getDeviceById(id);
			return res;
		},
		// eslint-disable-next-line no-unused-vars
		async getmedicaldevicebydeviceidentifier ({ commit }, id) {
			commit('LOAD_BLOOD_PRESURE_DATA', await MedicalDeviceRepository.getmedicaldevicebydeviceidentifier(id));
			// return res
		},
		// eslint-disable-next-line no-unused-vars
		async getMedicalDeviceTemplate ({ commit }) {
			commit('GET_MEDICAL_DEVICE_TEMPLATES', await MedicalDeviceRepository.getMedicalDeviceTemplate());
			// const res = await MedicalDeviceRepository.getMedicalDeviceTemplate()
			// return res
		},
		async getMedicalDeviceTemplateId ({ commit }, id) {
			const res = await MedicalDeviceRepository.getMedicalDeviceTemplateId(id);
			return res;
		},
		async getDeviceTemplateByManufacturer ({ commit }, object) {
			const res = await MedicalDeviceRepository.getDeviceTemplateByManufacturer(object);
			return res;
		},
		async getDeviceTemplatesByType ({ commit }, id) {
			commit('DEVICE_TEMPLATES', await MedicalDeviceRepository.getDeviceTemplatesByType(id));
		},
		async updateIsManualMeasurementForMedicalDevice ({ commit }, body) {
			commit('DEVICE_TEMPLATES', await MedicalDeviceRepository.updateIsManualMeasurementForMedicalDevice(body));
		},
		async getDeviceTemplatesByTypeAndManufacturer ({ commit }, body) {
			return await MedicalDeviceRepository.getDeviceTemplatesByTypeAndManufacturer(body);
		},
		// eslint-disable-next-line no-unused-vars
		async editMedicalDeviceTemplate ({ commit }, body) {
			const res = await MedicalDeviceRepository.editMedicalDeviceTemplate(body);
			return res;
		},
		async getmedicaldevicesforpatientV2 ({ commit }, id) {
			const res = await MedicalDeviceRepository.getmedicaldevicesforpatientV2(id);
			commit('PATIENT_DEVICES', res);
			return res;
		},
		async updateDeviceDefaultValuesJsonForMedicalDevice ({ commit }, body) {
			const res = await MedicalDeviceRepository.updateDeviceDefaultValuesJsonForMedicalDevice(body);
			return res;
		},
	},
	getters: {
		getPatientActiveTreatmentPlan (state) {
			const patientDevices = state.patientActiveTreatmentPlan.treatmentPlanDevicesList;
			return patientDevices;
		},
		getMedicalDevices (state) {
			const medicalDevices = state.medicalDevicesList[0].assignedMedicalDevicesList;
			return medicalDevices;
		},
		getMedicalDevicesList (state) {
			const medicalDevices = state.medicalDevicesList[0];
			return medicalDevices;
		},
	},
};
