import { UsersError } from '@/lib/Errors';
import _axios from '@/plugins/axios';
export default {
	async getReportsRelatedPatients (filter) {
		const response = await _axios.get('reporting/getallrelatedpatientdailyscores', { headers: { filterCase: filter } });
		if (response.data.resFlag) {
			return response.data.data;
		}
		throw new UsersError('No meta data!');
	},
	// async getAllReportsRelatedPatients (filter) {
	// 	const response = await _axios.get('reporting/getallrelatedpatientdailyscores', { headers: { filterCase: 'ALL' } });
	// 	if (response.data.resFlag) {
	// 		return response.data.data;
	// 	}
	// 	throw new UsersError('No meta data!');
	// },
	async getAllReportsRelatedPatients (data) {
		const response = await _axios.get('reporting/getallrelatedpatientdailyscores', { headers: { filterCase: data.filterCase, hospitalId: data.hospitalId } });
		if (response.data.resFlag) {
			return response.data.data;
		}
		throw new UsersError('No meta data!');
	},
};
