import Repository from '../../repositories/RepositoryFactory';
const UsersRepository = Repository.get('users');

export default {
	namespaced: true,
	state: {
		users: null,
		detailedUser: null,
		surveyUser: null,
		language: null,
		hospitalAdmins: null,
		selectedHospitalAdmin: null,
		organizationAdmins: null,
		selectedOrganizationAdmin: null,
		caregivers: null,
		selectedCaregiver: null,
	},
	mutations: {
		GET_USERS (state, payload) {
			var removeDuplicates = payload.data?.filter((v, i, a) => a.findIndex(t => (t.id === v.id)) === i);
			state.users = removeDuplicates;
		},
		GET_USER_BY_ID (state, payload) {
			state.detailedUser = payload;
		},
		CHANGE_LANGUAGE (state, payload) {
			state.language = payload;
		},
		GET_SURVEY_USERS (state, payload) {
			state.surveyUser = payload;
		},
		SET_USER_STATUS (state, payload) {
			state.detailedUser.status = payload.status;
		},
		GET_HOSPITAL_ADMINS (state, payload) {
			state.hospitalAdmins = payload;
		},
		SELECTED_HOSPITAL_ADMIN (state, payload) {
			state.selectedHospitalAdmin = payload;
		},
		GET_ORGANIZATION_ADMINS (state, payload) {
			state.organizationAdmins = payload;
		},
		SELECTED_ORGANIZATION_ADMIN (state, payload) {
			state.selectedOrganizationAdmin = payload;
		},
		GET_CARE_GIVERS (state, payload) {
			state.caregivers = payload;
		},
		SELECTED_CARE_GIVER (state, payload) {
			state.selectedCaregiver = payload;
		},
	},
	actions: {
		async setStatus ({ commit }, { id, status }) {
			const response = await UsersRepository.setStatus(id, status);
			commit('SET_USER_STATUS', response.data);
			return response;
		},
		async getUsers ({ commit }) {
			commit('GET_USERS', await UsersRepository.getUsers());
		},
		async changemyLanguage ({ commit }, body) {
			commit('CHANGE_LANGUAGE', await UsersRepository.changemyLanguage(body));
		},
		async getHospitalPatients ({ commit }) {
			commit('GET_USERS', await UsersRepository.getHospitalPatients());
		},
		// async getSurveyPatients ({ commit }) {
		// 	commit('GET_SURVEY_USERS', await UsersRepository.getSurveyPatients());
		// },
		async getSurveyPatients ({ commit }, hospitalId) {
			commit('GET_SURVEY_USERS', await UsersRepository.getSurveyPatients(hospitalId));
		},
		async getUserById ({ commit }, id) {
			commit('GET_USER_BY_ID', await UsersRepository.getUserById(id));
		},
		async createUser ({ commit }, body) {
			const res = await UsersRepository.createUser(body);
			// return res.data;
			return res;
		},
		// eslint-disable-next-line no-unused-vars
		async updateUser ({ commit }, body) {
			const res = await UsersRepository.updateUser(body);
			return res;
		},
		async setUserStatus ({ commit }, headers) {
			const res = await UsersRepository.setUserStatus(headers);
			return res;
		},
		// eslint-disable-next-line no-unused-vars
		async deleteUser ({ commit }, body) {
			const res = await UsersRepository.deleteUser(body);
			return res;
		},
		async deleteUser2 ({ commit }, body) {
			const res = await UsersRepository.deleteUser2(body);
			return res;
		},
		// eslint-disable-next-line no-unused-vars
		async changePassword ({ commit }, body) {
			const res = await UsersRepository.changePassword(body);
			return res;
		},
		// eslint-disable-next-line no-unused-vars
		async changePasswordWithValidationToken ({ commit }, body) {
			const res = await UsersRepository.changePasswordWithValidationToken(body);
			return res;
		},
		// eslint-disable-next-line no-unused-vars
		async sendValidationLinkByEmail ({ commit }, body) {
			const res = await UsersRepository.sendValidationLinkByEmail(body);
			return res;
		},
		// eslint-disable-next-line no-unused-vars
		async getmyuserprofilephoto ({ commit }) {
			const res = await UsersRepository.getmyuserprofilephoto();
			return res;
		},
		// eslint-disable-next-line no-unused-vars
		async uploadmyuserprofilephoto ({ commit }, formData) {
			const res = await UsersRepository.uploadmyuserprofilephoto(formData);
			return res;
		},
		async getHospitalAdmins ({ commit }, id) {
			commit('GET_HOSPITAL_ADMINS', await UsersRepository.getHospitalAdmins());
		},
		async getOrganizationAdmins ({ commit }, id) {
			commit('GET_ORGANIZATION_ADMINS', await UsersRepository.getOrganizationAdmins());
		},
		async editInvalidEmail ({ commit }, body) {
			const res = await UsersRepository.editInvalidEmail(body);
			return res;
		},
		async getCaregivers ({ commit }, id) {
			commit('GET_CARE_GIVERS', await UsersRepository.getCaregivers());
		},
	},
	getters: {
		getUserById (state) {
			return state.detailedUser?.userData;
		},
		async getUserByIdImage (state) {
			await state.detailedUser?.userProfileImage;
		},
		getHospitalAdmins (state) {
			return state.hospitalAdmins;
		},
		getSelectedHospitalAdmin (state) {
			return state.selectedHospitalAdmin;
		},
		getOrganizationAdmins (state) {
			return state.organizationAdmins;
		},
		getSelectedOrganizationAdmin (state) {
			return state.selectedOrganizationAdmin;
		},
		getCaregivers (state) {
			return state.caregivers;
		},
		getSelectedCaregiver (state) {
			return state.selectedCaregiver;
		},
	},
};
