import Repository from '../../repositories/RepositoryFactory';
const ReportsRepository = Repository.get('reportsRepo');

export default {
	namespaced: true,
	state: {
		reports: [],
		allReports: [],
	},
	mutations: {
		REPORTS_FOR_PATIENTS (state, payload) {
			state.reports = payload;
		},
		ALL_REPORTS_FOR_PATIENTS (state, payload) {
			state.allReports = payload;
		},
	},
	actions: {
		async getReportsRelatedPatients ({ commit }, data) {
			commit('REPORTS_FOR_PATIENTS', await ReportsRepository.getReportsRelatedPatients(data));
		},
		async getAllReportsRelatedPatients ({ commit }, data) {
			commit('ALL_REPORTS_FOR_PATIENTS', await ReportsRepository.getAllReportsRelatedPatients(data));
		},

	},
};
